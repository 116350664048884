<template>
    <b-container>
        <b-row class="my-1">
            <b-col sm="5">
                <label>Description</label>
            </b-col>
            <b-col sm="7" class="px-0">
                <b-form-textarea
                    id="textarea"
                    rows="3"
                    max-rows="6"
                    class="rowStyle"
                    v-model="eventLogContent.LogContent"
                ></b-form-textarea>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5">
                <label>Personnel</label>
            </b-col>
            <b-col sm="7" class="px-0">
                <b-form-input
                    class="rowStyle"
                    v-model="eventLogContent.PersonnelName"
                ></b-form-input>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5">
                <label
                    v-if="
                        projectInfo.ProjectTypeName == 'Emergency response' ||
                            projectInfo.ProjectTypeName ==
                                'Emergency management'
                    "
                    >ICS Role</label
                >
                <label v-else>Category</label>
            </b-col>
            <b-col sm="7" class="px-0">
                <!-- <b-form-input class="rowStyle" v-model="eventLogContent.ICSPosition"></b-form-input> -->
                <select
                    class="rowStyle"
                    id="sel1"
                    v-model="eventLogContent.ICSPosition"
                >
                    <option
                        v-for="(item, index) in EventLogPositions"
                        :key="index"
                        >{{ item }}</option
                    >
                </select>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5">
                <label>Timestamp</label>
            </b-col>
            <b-col sm="7" class="px-0">
                <!-- <b-form-datepicker class="rowStyle" v-model="eventLogContent.EventTS"></b-form-datepicker> -->
                <input
                    class="rowStyle"
                    type="datetime-local"
                    v-model="eventLogContent.EventTS"
                />
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5">
                <label>Location</label>
            </b-col>
            <b-col sm="7" class="px-0">
                <b-form-input
                    class="rowStyle"
                    v-model="eventLogContent.EventLogLocation"
                ></b-form-input>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col>
                <input
                    v-if="eventLogContent.Files.length == 0"
                    id="newLogUpload"
                    type="file"
                    name="newLogUpload"
                    @change="validateFile"
                />
                <div
                    v-else
                    v-for="(file, index) in eventLogContent.Files"
                    :key="index"
                >
                    {{ file.filename }}
                    <button class="btn btn-delete" @click="deleteFile(file)">
                        Delete
                    </button>
                </div>
            </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
            <button class="btn btn-submit" @click="submitLog">
                Submit
            </button>
        </b-row>
    </b-container>
</template>

<script>
import { mapState } from 'vuex';

import { commonService } from '../../services/common.service';
import { eventLogService } from '../../services/eventlog.service';

export default {
    components: {},
    name: 'EventLogModal',
    props: {
        eventID: Number,
        editContent: Object,
    },
    created() {},
    mounted() {
        if (this.editContent) {
            this.eventLogContent = this.editContent;
        } else {
            this.eventLogContent.EventID = this.eventID;
            this.eventLogContent.EventTS = new Date()
                .toISOString()
                .split('.')[0];
            this.eventLogContent.PersonnelName = this.$msalInstance.getAllAccounts()[0].name;
        }
    },
    data() {
        return {
            eventLogContent: {
                EventID: '',
                EventLogID: 0,
                LogContent: '',
                EventTS: '',
                EventLogLocation: '',
                PersonnelName: '',
                ICSPosition: '---',
                Files: [],
            },
        };
    },
    methods: {
        submitLog() {
            if (this.editContent) {
                this.saveEditToSQL().then(this.$emit('updateCOP'));
            } else {
                this.insertLogToSQL().then(this.$emit('updateCOP'));
            }
            this.eventLogContent = {
                EventID: this.eventID,
                EventLogID: 0,
                LogContent: '',
                EventTS: '',
                EventLogLocation: '',
                PersonnelName: this.$msalInstance.getAllAccounts()[0].name,
                ICSPosition: '---',
                Files: [],
            };
        },
        async insertLogToSQL() {
            let payload = this.eventLogContent;
            payload.EventTS = payload.EventTS.replace('T', ' ') + '.000';
            payload = commonService.objectToFormData(payload);
            try {
                payload.append(
                    '',
                    document.getElementById('newLogUpload').files[0]
                );
            } catch (error) {
                //nothing
            }
            this.$bvModal.hide('modal-createEventLog');
            eventLogService.createEventLog(payload).then((res) => {
                try {
                    if (res?.data?.StatusCode == 201) {
                        alert('Log created successfully');
                    } else {
                        alert('Log not created');
                    }
                } catch (error) {
                    alert('Error Occured');
                    console.log(error);
                }
            });
        },
        async saveEditToSQL(item) {
            let payload = this.eventLogContent;
            payload.EventTS = payload.EventTS.replace('T', ' ') + '.000';
            delete payload.Color;
            if (payload.GeoID == null) {
                delete payload.GeoID;
            }
            payload = commonService.objectToFormData(payload);
            try {
                payload.append(
                    '',
                    document.getElementById('newLogUpload').files[0]
                );
            } catch (error) {
                //nothing
            }
            eventLogService.updateEventLog(payload).then((res) => {
                try {
                    if (res?.data?.StatusCode == 202) {
                        alert('Log updated');
                    } else {
                        alert('Log not updated');
                    }
                } catch (error) {
                    alert('Error Occured');
                    console.log(error);
                }
            });
            this.$bvModal.hide(
                'modal-editEventLog-' + this.eventLogContent.EventLogID
            );
        },
        deleteFile() {
            eventLogService
                .deleteEventLogFile(this.editContent.EventLogID)
                .then((res) => {
                    try {
                        if (res?.data?.StatusCode == 200) {
                            alert('File Deleted');
                            this.eventLogContent.Files = [];
                            //refresh event logs;
                            this.$parent.getCOPData();
                        }
                    } catch (error) {
                        //nothing
                    }
                });
        },
        validateFile(event) {
            var file = event.target.files[0];
            var fileType = file.name
                .split('.')
                .pop()
                .toLowerCase();
            var validExtensions = [
                'docx',
                'doc',
                'xlsx',
                'xls',
                'pptx',
                'ppt',
                'pdf',
                'txt',
                'csv',
                'json',
                'xml',
                'jpg',
                'jpeg',
                'png',
                'gif',
                'svg',
                'bmp',
            ];
            if (validExtensions.indexOf(fileType) < 0) {
                alert(
                    `Invalid extension .${fileType}\nPlease select a valid document or image file.`
                );
                event.target.value = '';
            }
        },
    },
    computed: {
        ...mapState('emergencyManagement', ['EventLogPositions']),
        ...mapState('projects', ['selectedProjects']),
        projectInfo() {
            if (this.selectedProjects.length > 0) {
                return this.selectedProjects[0];
            } else {
                return { ProjectID: 0, ProjectTypeName: '' };
            }
        },
    },
    watch: {},
};
</script>

<style></style>
