<template>
    <div class="COP">
        <div class="eventTitle">
            {{ eventTitle
            }}<b-button
                class="btn btn-light btn-edit float-right"
                @click="editEventInfo"
                v-if="userIsAdmin"
                ><img src="../../assets/EM_edit.svg" />Edit</b-button
            >
        </div>
        <div>
            <div class="eventDescriptionOverflow">
                {{ eventDescription }}
            </div>
            <div>{{ eventLocation }}</div>
            <b-row class="eventLog">
                <b-col>
                    <b-button
                        v-if="userIsAdmin"
                        class="btn btn-eventLog"
                        @click="createNewLog"
                        >Create New Log Entry</b-button
                    >
                </b-col>
                <b-col cols="6" class="p-0">
                    <weather
                        displayMode="simple"
                        :lat="eventLat"
                        :long="eventLong"
                    />
                </b-col>
            </b-row>

            <div>
                <div class="eventTitle">
                    <span>Logs</span>
                    <button class="btn float-right" @click="exportData">
                        <img src="../../assets/export.svg" />Export
                    </button>
                </div>
                <div class="logs">
                    <event-log-item
                        v-for="(item, index) in eventLogs"
                        :key="index"
                        :eventLogItem="item"
                        @zoomToGeometry="zoomToGeometry"
                    />
                </div>
            </div>
        </div>
        <!-- create new event log -->
        <b-modal
            id="modal-createEventLog"
            centered
            hide-footer
            title="Create New Event Log"
        >
            <event-log-modal :eventID="eventID" @updateCOP="refreshCOPData">
            </event-log-modal>
        </b-modal>
        <!-- edit event log -->
        <b-modal
            id="modal-edit"
            centered
            hide-footer
            title="Edit Event Information"
        >
            <b-row class="my-1">
                <b-col sm="5">
                    <label>Event Title</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <b-form-input v-model="eventTitle" class="rowStyle">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="5">
                    <label>Event Location</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <b-form-input v-model="eventLocation" class="rowStyle">
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="my-1 d-flex justify-content-center">
                <b-col sm="5">
                    <label for="textarea">Event Description</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <b-form-textarea
                        id="textarea"
                        v-model="eventDescription"
                        rows="3"
                        max-rows="6"
                        class="rowStyle"
                    >
                    </b-form-textarea>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <button class="btn btn-submit" @click="updateEventInfo">
                    Submit
                </button>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import EventLogModal from './EventLogModal.vue';
import EventLogItem from './EventLogItem.vue';
import { eventLogService } from '../../services/eventlog.service';
import { commonService } from '../../services/common.service';
import { read, utils, writeFileXLSX } from 'xlsx';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Weather from '../CommonComponents/Weather.vue';
export default {
    components: {
        EventLogItem,
        EventLogModal,
        Weather,
    },
    name: 'CommonOperatingPicture',
    props: {
        dataFromParent: {
            type: Object,
        },
    },
    created() {
        this.getCOPData();
        this.startInterval();
    },
    beforeDestroy() {
        this.clearInterval();
    },
    mounted() {
        eventLogService.getEventID(this.projectInfo.ProjectID).then((res) => {
            this.eventID = res.data;
        });
        eventLogService
            .getEventLogPositions(this.projectInfo.ProjectID)
            .then((res) => {
                let positions = res.data;
                let positionNames = positions.map((position) => {
                    return position.Position;
                });
                this.$store.commit(
                    'emergencyManagement/setEventLogPositions',
                    positionNames
                );
            });
    },
    data() {
        return {
            dataExport: null,
            editInProgress: false,
            eventID: null,
            eventTitle: null,
            eventDescription: null,
            eventLocation: null,
            eventLat: null,
            eventLong: null,
            eventLogs: [],
            newEventLog: {
                EventID: '',
                EventLogID: 0,
                LogContent: '',
                EventTS: '',
                EventLogLocation: '',
                PersonnelName: '',
                ICSPosition: '',
            },
            userIsAdmin: true,
            menuOpen: false,
            eventLogDate: null,
        };
    },
    computed: {
        ...mapState('emergencyManagement', ['EventLogPositions']),
        newEvent: function() {
            return [
                {
                    label: 'EventTitle',
                    value: '',
                },
                {
                    label: 'EventDescription',
                    value: '',
                },
                {
                    label: 'EventLocation',
                    value: '',
                },
            ];
        },
        projectInfo: function() {
            return this.$store.state.projects.selectedProjects[0];
        },
        retrieveDateParam: function() {
            let unixDate =
                this.eventLogDate == null
                    ? Math.floor(new Date('1/1/2020') / 1000)
                    : this.eventLogDate;
            return unixDate;
        },
        ...mapGetters('projects', ['ProjectID']),
    },
    methods: {
        async exportData() {
            this.dataExport = this.eventLogs;
            const ws = utils.json_to_sheet(this.dataExport);
            const wbb = utils.book_new();
            utils.book_append_sheet(wbb, ws, 'Data');
            writeFileXLSX(wbb, 'LogSheet.xlsx');
        },
        updateEventInfo() {
            this.editInProgress = true;
            try {
                let payload = {
                    EventID: this.eventID,
                    ProjectID: this.projectInfo.ProjectID,
                    EventTitle: this.eventTitle,
                    EventLocation: this.eventLocation,
                    EventDescription: this.eventDescription,
                };
                eventLogService.updateEventInfo(payload).then((res) => {
                    alert('Event info updated!');
                    this.$bvModal.hide('modal-edit');
                    this.editInProgress = false;
                    this.getCOPData();
                });
            } catch (error) {
                this.editInProgress = false;
            }
        },
        refreshCOPData() {
            setTimeout(() => {
                this.getCOPData();
            }, 250);
        },
        getCOPData() {
            // call API and populate this.COPData
            // input: ProjectID
            if (!this.editInProgress) {
                try {
                    let projectID = this.$store.state.projects
                        .selectedProjects[0].ProjectID;
                    let retrieveDate = Math.floor(Date.now() / 1000);
                    eventLogService
                        .getEventLog(projectID, this.retrieveDateParam)
                        .then((res) => {
                            //
                            res.data.EventLogs.forEach((log) => {
                                try {
                                    log.Files.forEach((item) => {
                                        if (item.filetype == 'image') {
                                            item.src =
                                                'data:image/jpg; base64, iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=';
                                            commonService
                                                .downloadThumbnailByID(
                                                    item.documentID,
                                                    item.filename,
                                                    'image',
                                                    true
                                                )
                                                .then((resp) => {
                                                    item.src =
                                                        'data:image/jpg;base64, ' +
                                                        resp;
                                                });
                                        }
                                    });
                                } catch (error) {
                                    //nothing
                                }
                            });
                            this.eventTitle = res.data.EventTitle;
                            this.eventDescription = res.data.EventDescription;
                            this.eventLocation = res.data.EventLocation;
                            this.eventLat = res.data.EventLatLong[1];
                            this.eventLong = res.data.EventLatLong[0];
                            if (this.eventLogs.length == 0) {
                                this.eventLogs = res.data.EventLogs;
                            } else if (res.data.EventLogs.length > 0) {
                                this.logUpsert(
                                    this.eventLogs,
                                    res.data.EventLogs
                                );
                            }
                            // remove any logs that are in the Deleted[] return
                            if (res.data.Deleted.length > 0) {
                                let idsToDelete = res.data.Deleted;
                                let filteredEventLogs = this.eventLogs.filter(
                                    (log) => {
                                        return !idsToDelete.includes(
                                            log.EventLogID
                                        );
                                    }
                                );
                                this.eventLogs = filteredEventLogs;
                            }
                            this.newEventLog.EventID = this.eventID;
                            this.newEventLog.PersonnelName = this.$msalInstance.getAllAccounts()[0].name;
                            this.eventLogDate = retrieveDate;
                        });
                } catch (error) {
                    //nothing
                }
            }
        },
        startInterval: function() {
            //refresh Event Log 30 seconds
            this.refreshData = setInterval(() => {
                this.getCOPData();
            }, 30000);
        },
        clearInterval: function() {
            clearTimeout(this.refreshData);
        },
        createNewLog() {
            //show modal to create a new log;
            this.newEventLog.EventTS = new Date().toISOString().split('.')[0];
            this.$bvModal.show('modal-createEventLog');
        },
        zoomToIdentifyResult(index) {
            this.$emit('zoomToGeometryByIndex', index);
        },
        zoomToGeometry(geometry) {
            this.$emit('zoomTo', geometry, 3857);
        },
        editIdentifyResult(index) {
            this.$emit('editGeometryByIndex', index);
        },
        runHCAQuery(index) {
            this.$emit('runHCAbyIndex', index);
        },
        editEventInfo() {
            this.editInProgress = true;
            this.$bvModal.show('modal-edit');
        },
        saveEditToSQL(item) {},
        insertLogToSQL() {
            //
            let payload = this.newEventLog;
            payload.EventTS = payload.EventTS.replace('T', ' ') + '.000';
            payload = commonService.objectToFormData(this.newEventLog);
            try {
                payload.append(
                    '',
                    document.getElementById('newLogUpload').files[0]
                );
            } catch (error) {
                //nothing
            }
            this.$bvModal.hide('modal-createEventLog');
            eventLogService.createEventLog(payload).then((res) => {
                //
                try {
                    if (res.data.Message.indexOf('success') > 0) {
                        alert('Log created successfully');
                        //refresh event logs;
                        this.getCOPData();
                    }
                } catch (error) {
                    alert('error occured');
                    this.getCOPData();
                }
                this.newEventLog = {
                    EventID: '',
                    EventLogID: 0,
                    LogContent: '',
                    EventTS: '',
                    EventLogLocation: '',
                    PersonnelName: '',
                    ICSPosition: '',
                };
            });
        },
        logUpsert(currentLogs, newLogs) {
            const updatedLogs = [...currentLogs];
            for (let i = 0; i < newLogs.length; i++) {
                const newLog = newLogs[i];
                const existingLogIndex = updatedLogs.findIndex(
                    (log) => log.EventLogID === newLog.EventLogID
                );
                if (existingLogIndex !== -1) {
                    updatedLogs[existingLogIndex] = newLog;
                } else {
                    updatedLogs.unshift(newLog);
                }
            }
            this.eventLogs = updatedLogs;
        },
    },
    watch: {
        projectInfo(newProjectInfo) {
            //dummy test for development
            this.eventID = null;
            this.eventTitle = null;
            this.eventDescription = null;
            this.eventLocation = null;
            this.eventLat = null;
            this.eventLong = null;
            this.eventLogs = [];
            this.getCOPData();
        },
        async ProjectID(newProjectID, oldProjectID) {
            this.eventLogDate = null;
            if (newProjectID != oldProjectID) {
                eventLogService
                    .getEventID(this.projectInfo.ProjectID)
                    .then((res) => {
                        this.eventID = res.data;
                    });
                eventLogService
                    .getEventLogPositions(this.projectInfo.ProjectID)
                    .then((res) => {
                        let positions = res.data;
                        let positionNames = positions.map((position) => {
                            return position.Position;
                        });
                        this.$store.commit(
                            'emergencyManagement/setEventLogPositions',
                            positionNames
                        );
                    });
                this.getCOPData();
                this.startInterval();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.COP {
    /* width: 500px; */
    padding: 20px;
    /* border: solid 1px black; */
}
.btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 40px;
    /* need correct font-family */
}
.btn-submit {
    background-color: #006395;
    color: white;
    height: 36px;
    &:hover {
        background-color: #00496d !important;
        color: #fff;
    }
}
.btn-eventLog {
    background-color: #006395;
    color: white;
    height: 36px;
    &:hover {
        background-color: #00496d !important;
        color: #fff;
    }
}
.btn-submit {
    background-color: #006395;
    color: white;
    height: 36px;
    &:hover {
        background-color: #00496d !important;
        color: #fff;
    }
}
.btn-edit {
    color: gray;
}
.eventTitle {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 15px;
    font-weight: bold;
}
.eventLog {
    padding-top: 10px;
    padding-bottom: 10px;
}
.logs {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 2px;
}
/* styles for MENU dropdown */

/* responsive styles for Logs */
@media only screen and (min-height: 1201px) {
    .logs {
        height: 840px;
    }
}
@media only screen and (max-height: 1200px) {
    .logs {
        height: 780px;
    }
}
@media only screen and (max-height: 1150px) {
    .logs {
        height: 740px;
    }
}
@media only screen and (max-height: 1100px) {
    .logs {
        height: 700px;
    }
}
@media only screen and (max-height: 1050px) {
    .logs {
        height: 650px;
    }
}
@media only screen and (max-height: 1000px) {
    .logs {
        height: 600px;
    }
}
@media only screen and (max-height: 950px) {
    .logs {
        height: 555px;
    }
}
@media only screen and (max-height: 900px) {
    .logs {
        height: 510px;
    }
}
@media only screen and (max-height: 850px) {
    .logs {
        height: 460px;
    }
}
@media only screen and (max-height: 800px) {
    .logs {
        height: 410px;
    }
}
@media only screen and (max-height: 750px) {
    .logs {
        height: 370px;
    }
}
@media only screen and (max-height: 700px) {
    .logs {
        height: 320px;
    }
}
@media only screen and (max-height: 650px) {
    .logs {
        height: 270px;
    }
}
@media only screen and (max-height: 600px) {
    .logs {
        height: 220px;
    }
}
@media only screen and (max-height: 550px) {
    .logs {
        height: 180px;
    }
}
@media only screen and (max-height: 500px) {
    .logs {
        height: 90px;
    }
}
/* end of responsive styles for Logs */

/* Dropdown Button */
.dropbtn {
    background-color: #3498db;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
    background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    position: absolute;
    background-color: white;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}
.eventDescriptionOverflow {
    max-height: 150px;
    overflow: auto;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.rowStyle {
    border-radius: 4px !important;
    width: 90% !important;
}
#myDropdown {
    border-radius: 4px;
    z-index: 9999;
}
</style>
